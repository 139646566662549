var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.beneficiaries,"search":_vm.search,"sort-by":"createdAt","group-by":"cnpj"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.openEmailDialog(group)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar Email")])]),_vm._v(" "),_c('span',[_vm._v(_vm._s(group))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showMoreDatails)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.openDetails(group)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Mais Detalhes")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.showMoreDatails)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.downloadCpfPdf(_vm.beneficiaries)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download CPT em PDF")])]),(_vm.isOnboardingTeam)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.showMoreDatails)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"green darken-2"},on:{"click":function($event){return _vm.downloadCsv(_vm.beneficiaries)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-excel-box ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download CSV")])]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-col',{class:{ 'cursor-pointer': _vm.canEditProposal },attrs:{"readonly":!_vm.canEditProposal},on:{"click":function($event){return _vm.openDpsEditDialog(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.editedName)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Editado por: "+_vm._s(item.editedName)+" às "+_vm._s(_vm._f("moment")(item.updatedAt,"DD/MM/YYYY HH:mm:ss"))+" ")])]),_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.lastName))]),(_vm.canEditProposal)?_c('v-icon',{staticClass:"mdi-pencil",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('br'),_c('span',[_vm._v(_vm._s(item.cpf))])],1)]}},{key:"item.sex",fn:function(ref){
var item = ref.item;
return [_c('v-col',{class:{ 'cursor-pointer': _vm.canEditProposal },attrs:{"cols":"12","readonly":!_vm.canEditProposal}},[_c('span',[_vm._v(_vm._s(item.sex))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-col',{class:{ 'cursor-pointer': _vm.canEditProposal },attrs:{"cols":"12","readonly":!_vm.canEditProposal}},[_c('span',[_vm._v(_vm._s(item.email))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.phoneMask(item.phone)))])])]}},{key:"item.birthDate",fn:function(ref){
var item = ref.item;
return [_c('v-col',{class:{ 'cursor-pointer': _vm.canEditProposal },attrs:{"cols":"12","readonly":!_vm.canEditProposal}},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.birthDate,"DD/MM/YYYY")))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.insurer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInsurer(item.insurer))+" ")]}},{key:"item.signedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.signedDate,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [_c('v-col',{attrs:{"cols":"10"}},[(!_vm.isHealthTeam)?_c('span',[_c('span',{staticClass:"column-step",class:_vm.getClass(item.step)},[_vm._v(" "+_vm._s(item.step || "Comercial")+" ")])]):_c('span',{class:_vm.getClass(item.step),attrs:{"cols":"10"}},[_c('ChangeStep',{attrs:{"item":item,"beneficiaries":_vm.beneficiaries}})],1)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === true || item.status === 'true')?_c('span',{staticClass:"approved rectangle"}):(item.status === 'imc')?_c('span',{staticClass:"review rectangle"}):(item.status === false || item.status === 'false')?_c('span',{staticClass:"refuse rectangle"}):_vm._e()]}},{key:"item.immediateMember",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"success"},on:{"change":function($event){return _vm.updateImmediateMember(item)}},model:{value:(item.immediateMember),callback:function ($$v) {_vm.$set(item, "immediateMember", $$v)},expression:"item.immediateMember"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[(_vm.canDelete(item))?_c('DeleteItem',{attrs:{"item":item,"items":_vm.beneficiaries}}):_vm._e(),(_vm.isHealthTeam)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action",attrs:{"icon":"","outlined":"","color":"secondary","elevation":"3","target":"_blank"},on:{"click":function($event){return _vm.openDps(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver DPS")])]):_vm._e(),(_vm.isHealthTeam || _vm.isOnboardingTeam)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showCpt(item))?_c('div',{staticClass:"action"},[_c('v-badge',{attrs:{"color":"error","icon":"mdi-exclamation-thick","overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","outlined":"","elevation":"3","icon":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.openCptDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-document ")])],1)],1)],1):_c('div',{staticClass:"action"},[_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","elevation":"3","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openCptDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-document ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("CPT")])]):_vm._e(),(_vm.isHealthTeam)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action",attrs:{"icon":"","outlined":"","color":"secondary","elevation":"3","target":"_blank"},on:{"click":function($event){return _vm.openPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-find ")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]):_vm._e()],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},[_c('EditModal',{attrs:{"item":_vm.editItem,"toggle":_vm.toggleEdit}})],1),_c('v-dialog',{attrs:{"max-width":"1360px","scrollable":""},model:{value:(_vm.dps.showDps),callback:function ($$v) {_vm.$set(_vm.dps, "showDps", $$v)},expression:"dps.showDps"}},[_c('ViewDps',{attrs:{"toggle":_vm.toggleDps,"item":_vm.dps.item}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.cpt.show),callback:function ($$v) {_vm.$set(_vm.cpt, "show", $$v)},expression:"cpt.show"}},[_c('CPT',{attrs:{"toggle":_vm.toggleCpt,"item":_vm.cpt.item}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.email.show),callback:function ($$v) {_vm.$set(_vm.email, "show", $$v)},expression:"email.show"}},[_c('Email',{attrs:{"toggle":_vm.toggleEmail,"items":_vm.email.items}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }