/* eslint-disable */
import { API, Auth } from "aws-amplify";

const post = async ({ apiName = "dpsapi", path, body = {}, headers = {} }) => {
  return await API.post(apiName, path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      ...headers
    },
    body
  });
};

const get = async ({ apiName = "dpsapi", path, headers = {} }) => {
  return await API.get(apiName, path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      ...headers
    },
  });
}

const del = async ({ apiName = "dpsapi", path, headers = {} }) => {
  return await API.del(apiName, path, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      ...headers
    },
  });
}

export { post, get, del }
