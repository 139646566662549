<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="beneficiaries"
      :search="search"
      class="elevation-1"
      sort-by="createdAt"
      group-by="cnpj"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:group.header="{ group, headers }">
        <td :colspan="headers.length">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
                @click.stop="openEmailDialog(group)"
              >
                <v-icon small> mdi-email </v-icon>
              </v-btn>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
          &nbsp;
          <span>{{ group }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
                @click.stop="openDetails(group)"
                v-if="showMoreDatails"
              >
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Mais Detalhes</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
                @click="downloadCpfPdf(beneficiaries)"
                v-if="!showMoreDatails"
              >
                <v-icon small> mdi-download </v-icon>
              </v-btn>
            </template>
            <span>Download CPT em PDF</span>
          </v-tooltip>
          <v-tooltip top v-if="isOnboardingTeam">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="green darken-2"
                v-bind="attrs"
                v-on="on"
                @click="downloadCsv(beneficiaries)"
                v-if="!showMoreDatails"
              >
                <v-icon small>  mdi-file-excel-box </v-icon>
              </v-btn>
            </template>
            <span>Download CSV</span>
          </v-tooltip>
        </td>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <v-col
          :readonly="!canEditProposal"
          @click="openDpsEditDialog(item)"
          :class="{ 'cursor-pointer': canEditProposal }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="item.editedName" small icon color="warning" v-bind="attrs" v-on="on">
                <v-icon small>
                  mdi-alert
                </v-icon>
              </v-btn>
            </template>
            <span>
              Editado por: {{ item.editedName }} às
              {{ item.updatedAt | moment("DD/MM/YYYY HH:mm:ss") }}
            </span>
          </v-tooltip>
          <span>{{ item.name }} {{ item.lastName }}</span>
          <v-icon v-if="canEditProposal" class="mdi-pencil" small>
            mdi-pencil
          </v-icon>
          <br />
          <span>{{ item.cpf }}</span>
        </v-col>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.sex="{ item }">
        <v-col
          cols="12"
          :readonly="!canEditProposal"
          :class="{ 'cursor-pointer': canEditProposal }"
        >
          <span>{{ item.sex }}</span>
        </v-col>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.email="{ item }">
        <v-col
          cols="12"
          :readonly="!canEditProposal"
          :class="{ 'cursor-pointer': canEditProposal }"
        >
          <span>{{ item.email }}</span>
          <br />
          <span>{{ phoneMask(item.phone) }}</span>
        </v-col>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.birthDate="{ item }">
        <v-col
          cols="12"
          :readonly="!canEditProposal"
          :class="{ 'cursor-pointer': canEditProposal }"
        >
          <span>{{ item.birthDate | moment("DD/MM/YYYY") }}</span>
        </v-col>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment("DD/MM/YYYY HH:mm") }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.insurer="{ item }">
        {{ getInsurer(item.insurer) }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.signedDate="{ item }">
        {{ item.signedDate | moment("DD/MM/YYYY HH:mm") }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.step="{ item }">
        <v-col cols="10">
          <span v-if="!isHealthTeam">
            <span class="column-step" :class="getClass(item.step)">
              {{ item.step || "Comercial" }}
            </span>
          </span>
          <span cols="10" :class="getClass(item.step)" v-else>
            <ChangeStep :item="item" :beneficiaries="beneficiaries" />
          </span>
        </v-col>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <span class="approved rectangle" v-if="item.status === true || item.status === 'true'" />
        <span class="review rectangle" v-else-if="item.status === 'imc'" />
        <span
          class="refuse rectangle"
          v-else-if="item.status === false || item.status === 'false'"
        />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.immediateMember="{ item }">
        <v-checkbox
          v-model="item.immediateMember"
          @change="updateImmediateMember(item)"
          color="success"
        ></v-checkbox>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.action="{ item }">
        <div class="actions">
          <DeleteItem v-if="canDelete(item)" :item="item" :items="beneficiaries" />
          <v-tooltip top v-if="isHealthTeam">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                outlined
                color="secondary"
                elevation="3"
                target="_blank"
                class="action"
                v-bind="attrs"
                v-on="on"
                @click="openDps(item)"
              >
                <v-icon small>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <span>Ver DPS</span>
          </v-tooltip>
          <v-tooltip top v-if="isHealthTeam || isOnboardingTeam">
            <template v-slot:activator="{ on, attrs }">
              <div v-if="showCpt(item)" class="action">
                <v-badge color="error" icon="mdi-exclamation-thick" overlap>
                  <v-btn
                    color="error"
                    outlined
                    elevation="3"
                    icon
                    depressed
                    @click.stop="openCptDialog(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-file-document </v-icon>
                  </v-btn>
                </v-badge>
              </div>
              <div v-else class="action">
                <v-btn
                  outlined
                  elevation="3"
                  icon
                  @click.stop="openCptDialog(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small> mdi-file-document </v-icon>
                </v-btn>
              </div>
            </template>
            <span>CPT</span>
          </v-tooltip>
          <v-tooltip top v-if="isHealthTeam">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                outlined
                color="secondary"
                elevation="3"
                target="_blank"
                class="action"
                v-bind="attrs"
                v-on="on"
                @click="openPdf(item)"
              >
                <v-icon small>
                  mdi-file-find
                </v-icon>
              </v-btn>
            </template>
            <span>PDF</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showEdit" persistent max-width="600px">
      <EditModal :item="editItem" :toggle="toggleEdit" />
    </v-dialog>
    <v-dialog v-model="dps.showDps" max-width="1360px" scrollable>
      <ViewDps :toggle="toggleDps" :item="dps.item" />
    </v-dialog>
    <v-dialog v-model="cpt.show" persistent max-width="600px">
      <CPT :toggle="toggleCpt" :item="cpt.item" />
    </v-dialog>
    <v-dialog v-model="email.show" persistent max-width="600px">
      <Email :toggle="toggleEmail" :items="email.items" />
    </v-dialog>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { mask } from "@/utils/mask";
import { get, post } from "@/utils/api";
import { API } from "aws-amplify";
import { updateDps } from "@/graphql/mutations";
import { getSentEmails } from "@/graphql/queries";
import { headers, insurers } from "@/utils/dps.utils";
import { isEmpty, get as getValue } from "lodash";
import { InsurersEnum } from "@/utils/enum";
import downloadBlob from "@/utils/downloadBlob";
import unimedCptExport from "@/utils/csvExport/unimedCptExport";

export default {
  name: "DataTable",
  components: {
    EditModal: () => import("@/components/EditModal.vue"),
    ViewDps: () => import("@/components/ViewDps/ViewDps.vue"),
    CPT: () => import("@/components/CPT/CPT.vue"),
    Email: () => import("@/components/Email/Email.vue"),
    ChangeStep: () => import("@/components/ChangeStep/ChangeStep.vue"),
    DeleteItem: () => import("@/components/DeleteItem/DeleteItem.vue")
  },
  props: ["beneficiaries", "showMoreDatails", "search"],
  data() {
    return {
      editItem: {},
      showEdit: false,
      deleteProposal: false,
      deletedProposal: null,
      dps: {
        item: {},
        showDps: false
      },
      cpt: {
        show: false,
        item: {}
      },
      email: {
        show: false,
        items: []
      }
    };
  },
  methods: {
    showCpt(item) {
      return !!item.cpt;
    },
    openDetails(group) {
      const cnpj = group.replace(/\D/g, "");
      this.$router.push(`/dps/${cnpj}`);
    },
    async downloadCpfPdf(beneficiaries) {
      const beneficiariesWithCpt = beneficiaries.filter(
        b => !isEmpty(b.cpt) && (b.insurer === null || b.insurer === "unimed")
      );
      if (beneficiariesWithCpt.length <= 0) return;
      const body = {
        beneficiaries: beneficiariesWithCpt
      };
      try {
        this.$store.commit("loading", true);

        const { data } = await post({ path: "/dps/cpt-pdf", body });
        if (data) {
          downloadBlob({
            type: "application/zip",
            filename: `${beneficiariesWithCpt[0].cnpj}-cpts.zip`,
            content: Uint8Array.from(data.data).buffer
          });
        }
      } catch (error) {
        console.error(error);
        this.$store.commit("showAlert", {
          type: "error",
          message: "Erro ao baixar arquivos de CPT"
        });
      } finally {
        this.$store.commit("loading", false);
      }
    },
    async downloadCsv(beneficiaries) {
      if (!beneficiaries.every((b) => b.insurer === null || b.insurer === InsurersEnum.unimed)) {
        return this.$store.commit("showAlert", {
          type: "error",
          message: "Esse download só é permitido para seguradora unimed"
        });
      }
      try {
        this.$store.commit("loading", true);

        const { data } = await API.graphql({
          query: getSentEmails,
          variables: {
            cnpj: beneficiaries[0].cnpj,
          },
        });
        const startDate = getValue(data, 'getSentEmails.startDate')
        unimedCptExport(beneficiaries, startDate);

        this.$store.commit("showAlert", {
          type: "success",
          message: "Arquivo gerado com sucesso"
        });
      } catch (error) {
        console.error(error);
        this.$store.commit("showAlert", {
          type: "error",
          message: "Erro ao baixar arquivos csv"
        });
      } finally {
        this.$store.commit("loading", false);
      }
    },
    getClass(step) {
      if (step == "Recusado") return "refuse";
      if (step == "Aprovado") return "approved";
      return "review";
    },
    openDpsEditDialog(item) {
      if (this.canEditProposal) {
        this.editItem = item;
        this.toggleEdit();
      }
    },
    toggleEmail() {
      this.email.show = !this.email.show;
    },
    openEmailDialog(group) {
      const items = this.beneficiaries.filter(beneficiary => beneficiary.cnpj === group);
      this.email.items = items;
      this.toggleEmail();
    },
    toggleEdit() {
      this.showEdit = !this.showEdit;
    },
    openDps(item) {
      this.dps.item = item;
      this.toggleDps();
    },
    toggleDps() {
      this.dps.showDps = !this.dps.showDps;
    },
    phoneMask(phone) {
      return mask(phone, "(##) #####-####");
    },
    openCptDialog(item) {
      this.cpt.item = item;
      this.toggleCpt();
    },
    toggleCpt() {
      this.cpt.show = !this.cpt.show;
    },
    async updateImmediateMember(item) {
      await API.graphql({
        query: updateDps,
        variables: {
          input: item
        }
      });
    },
    canDelete(item) {
      return (
        this.isComercialTeam &&
        isEmpty(item.signedDate) &&
        (item.step === "Comercial" || item.step === "Recusado")
      );
    },
    async openPdf(item) {
      try {
        this.$store.commit("loading", true);
        const { fileUrl } = await get({ path: `/dps/download/${item.documentKey || item.id}` });
        window.open(fileUrl, "_blank");
      } catch (error) {
        this.$store.commit("showAlert", { type: "error", message: "Erro ao baixar pdf" });
      } finally {
        this.$store.commit("loading", false);
      }
    },
    getInsurer(value) {
      if (value) return insurers.find(insurer => insurer.value === value).text;
      else return "Unimed";
    }
  },
  computed: {
    cnpj() {
      return mask(this.$route.params.cnpj, "##.###.###/####-##");
    },
    canEditProposal() {
      return this.$store.state.user.canEditProposal;
    },
    isHealthTeam() {
      return this.$store.state.user.isHealthTeam;
    },
    isComercialTeam() {
      return (
        (!this.isHealthTeam && !this.isOnboardingTeam) || this.$store.state.user.isComercialTeam
      );
    },
    isOnboardingTeam() {
      return this.$store.state.user.isOnboardingTeam;
    },
    headers() {
      const showFull = this.isComercialTeam;
      const showImmediateMember = !this.isComercialTeam;
      const showStatus = this.isHealthTeam;
      return headers({ showFull, showImmediateMember, showStatus });
    }
  }
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer > span:hover {
  text-decoration: underline;
}
.actions {
  display: flex;
  justify-content: flex-start;
}

.action {
  margin: 10px !important;
}

span.column-step.approved,
td span.approved .v-select__selection--comma,
span.approved {
  color: #4caf50 !important;
  cursor: not-allowed;
}

span.column-step.refuse,
td span.refuse .v-select__selection--comma,
span.refuse {
  color: #ff5252 !important;
}

span.column-step.review,
td span.review .v-select__selection--comma,
span.review {
  color: #ff9800 !important;
}

.v-dialog {
  overflow: hidden !important;
}

span.rectangle.approved {
  background: #4caf50;
}

span.rectangle.review {
  background: #ff9800;
}

span.rectangle.refuse {
  background: #ff5252;
}

.rectangle {
  width: 24px;
  height: 24px;
  display: block;
}
</style>
