// import { Parser as jsonParser } from 'json2csv';
import { parse as jsonParser } from 'json2csv';

import downloadBlob from '../downloadBlob';

export default (arrayItem, startDate) => {
  const csv = jsonParser(arrayItem, {
    fields: [
      {
        label: 'Tipo Movto',
        value: () => 'I',
      },
      {
        label: "Cód U' Seg",
        value: () => '994',
      },
      'Número Sequencial',
      'Código Família',
      'Relação Dep.',
      'Dígito',
      {
        label: 'Data Nascimento',
        value: (row) => new Date(row.birthDate).toLocaleDateString('pt-BR'),
      },
      {
        label: 'Sexo',
        value: (row) => row.sex?.slice(0, 1),
      },
      'Estado Civil',
      {
        label: 'Data Inclusão / Exclusão',
        value: () => (startDate ? new Date(startDate).toLocaleDateString('pt-BR') : ''),
      },
      'Plano/ Prod',
      {
        label: 'Nome Segurado',
        value: (row) => `${row.name} ${row.lastName}`,
      },
      {
        label: 'CPF',
        value: (row) => `${row.cpf.replace(/\D/g, '')}`,
      },
      'Cidade',
      'UF',
      'Admissão',
      {
        label: 'Nome da Mãe',
        value: 'motherName',
      },
      'Endereço',
      'Número',
      'Complemento',
      'Bairro',
      'CEP',
      'PIS/PASEP',
      'Matricula',
      'Lotação do Func.',
      'Declaração Nascido Vivo',
      'Cartão Nacional de Saúde',
      {
        label: 'DDD Celular',
        value: (row) => `${row.phone?.slice(0, 2)}`,
      },
      {
        label: 'Celular',
        value: (row) => `${row.phone?.slice(2)}`,
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      'Motivo Exclusão',
      'Cidade Residencia',
      'UF Residencia',
      'Código Empresa',
      'Número Empresa',
      {
        label: 'ISENTA CARÊNCIA',
        value: () => 'N',
      },
      {
        label: 'Carência',
        value: () => 'CP',
      },
      {
        label: 'CPT',
        value: 'cpt',
      },
    ],
  });

  downloadBlob({
    type: 'text/csv',
    filename: `${arrayItem[0].cnpj}-unimed-cpt.csv`,
    content: csv,
  });
};
