/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDps = /* GraphQL */ `
  mutation CreateDps(
    $input: CreateDpsInput!
    $condition: ModelDpsConditionInput
  ) {
    createDps(input: $input, condition: $condition) {
      id
      cnpj
      vendor
      cpf
      sex
      email
      phone
      birthDate
      name
      status
      lastName
      cpt
      step
      immediateMember
      signedDate
      createdAt
      updatedAt
      documentKey
      editedName
      insurer
      acceptedTerm
      motherName
    }
  }
`;
export const updateDps = /* GraphQL */ `
  mutation UpdateDps(
    $input: UpdateDpsInput!
    $condition: ModelDpsConditionInput
  ) {
    updateDps(input: $input, condition: $condition) {
      id
      cnpj
      vendor
      cpf
      sex
      email
      phone
      birthDate
      name
      status
      lastName
      cpt
      step
      immediateMember
      signedDate
      createdAt
      updatedAt
      documentKey
      editedName
      insurer
      acceptedTerm
      motherName
    }
  }
`;
export const deleteDps = /* GraphQL */ `
  mutation DeleteDps(
    $input: DeleteDpsInput!
    $condition: ModelDpsConditionInput
  ) {
    deleteDps(input: $input, condition: $condition) {
      id
      cnpj
      vendor
      cpf
      sex
      email
      phone
      birthDate
      name
      status
      lastName
      cpt
      step
      immediateMember
      signedDate
      createdAt
      updatedAt
      documentKey
      editedName
      insurer
      acceptedTerm
      motherName
    }
  }
`;
export const createSentEmails = /* GraphQL */ `
  mutation CreateSentEmails(
    $input: CreateSentEmailsInput!
    $condition: ModelSentEmailsConditionInput
  ) {
    createSentEmails(input: $input, condition: $condition) {
      cnpj
      insurer
      currentPlan
      totalMonthlyPremium
      closedPlan
      typeSubscription
      startDate
      closerEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateSentEmails = /* GraphQL */ `
  mutation UpdateSentEmails(
    $input: UpdateSentEmailsInput!
    $condition: ModelSentEmailsConditionInput
  ) {
    updateSentEmails(input: $input, condition: $condition) {
      cnpj
      insurer
      currentPlan
      totalMonthlyPremium
      closedPlan
      typeSubscription
      startDate
      closerEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteSentEmails = /* GraphQL */ `
  mutation DeleteSentEmails(
    $input: DeleteSentEmailsInput!
    $condition: ModelSentEmailsConditionInput
  ) {
    deleteSentEmails(input: $input, condition: $condition) {
      cnpj
      insurer
      currentPlan
      totalMonthlyPremium
      closedPlan
      typeSubscription
      startDate
      closerEmail
      createdAt
      updatedAt
    }
  }
`;
export const createSentEmailCpt = /* GraphQL */ `
  mutation CreateSentEmailCpt(
    $input: CreateSentEmailCPTInput!
    $condition: ModelSentEmailCPTConditionInput
  ) {
    createSentEmailCPT(input: $input, condition: $condition) {
      id
      cnpj
      name
      email
      document
      status
      insurer
      createdAt
      updatedAt
    }
  }
`;
export const updateSentEmailCpt = /* GraphQL */ `
  mutation UpdateSentEmailCpt(
    $input: UpdateSentEmailCPTInput!
    $condition: ModelSentEmailCPTConditionInput
  ) {
    updateSentEmailCPT(input: $input, condition: $condition) {
      id
      cnpj
      name
      email
      document
      status
      insurer
      createdAt
      updatedAt
    }
  }
`;
export const deleteSentEmailCpt = /* GraphQL */ `
  mutation DeleteSentEmailCpt(
    $input: DeleteSentEmailCPTInput!
    $condition: ModelSentEmailCPTConditionInput
  ) {
    deleteSentEmailCPT(input: $input, condition: $condition) {
      id
      cnpj
      name
      email
      document
      status
      insurer
      createdAt
      updatedAt
    }
  }
`;
